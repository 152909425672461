import basicLayout from "@/layouts/Basic";

export default [
  {
    path: "/niceui",
    name: "niceui",
    meta: {
      title: "niceui",
    },
    component: basicLayout,
    children: [
      {
        path: "",
        name: "nav",
        meta: {
          title: "nav",
        },
        component: () => import("@/views/niceui/nav"),
      },
    ],
  },
];
