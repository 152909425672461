// ****** tool.js ******

const debounce = function(fn, delay) {
  return function() {
    if (fn.id) clearTimeout(fn.id);
    fn.id = setTimeout(() => {
      fn.apply(this, arguments);
    }, delay);
  };
};

const throttle = function(fn, delay) {
  return function() {
    if (fn.id) return;
    fn.id = true;
    setTimeout(() => {
      fn.apply(this, arguments);
      fn.id = false;
    }, delay);
  };
};

/**
 * 递归获取当前节点的所有父级节点
 * @param { Object } opts   所有节点
 * @param { Object } opt    当前节点
 * @param { Object } param  函数的配置参数, 可以为空 { key: 唯一标识, value: 可以为空, next: 继续递归的标记 }
 * @param { Array }  path
 * @author SLVD
 * @date 2020-10-15
 */
const cascade = (opts, opt, param, path) => {
  // debugger;
  let e = { key: "key", value: "", next: "children" }; // value为false时，返回对象
  if (JSON.stringify(e) !== JSON.stringify(param)) Object.assign(e, param);
  if (path === undefined) path = [];
  for (let obj of opts) {
    let temPath = path.concat(),
      tmp = null;
    e.value ? (tmp = obj[e.value]) : (tmp = obj); // 如果为空，返回当前对象
    temPath.push(tmp);
    if (opt[e.key] === obj[e.key]) {
      return temPath;
    }
    if (obj[e.next]) {
      let findResult = cascade(obj[e.next], opt, e, temPath);
      if (findResult) {
        return findResult;
      }
    }
  }
};

const typeOf = (obj) => {
  const toString = Object.prototype.toString;
  const map = {
    "[object Boolean]": "boolean",
    "[object Number]": "number",
    "[object String]": "string",
    "[object Function]": "function",
    "[object Array]": "array",
    "[object Date]": "date",
    "[object RegExp]": "regExp",
    "[object Undefined]": "undefined",
    "[object Null]": "null",
    "[object Object]": "object",
  };
  return map[toString.call(obj)];
};

/**
 * COPY
 * 深克隆
 * @param { * }  => var object = deepCopy(obj)
 * @author SLVD
 * @date 2020-10-05
 */
const deepCopy = (data) => {
  const t = typeOf(data);
  let o;

  if (t === "array") {
    o = [];
  } else if (t === "object") {
    o = {};
  } else {
    return data;
  }

  if (t === "array") {
    for (let i = 0; i < data.length; i++) {
      o.push(deepCopy(data[i]));
    }
  } else if (t === "object") {
    for (let i in data) {
      o[i] = deepCopy(data[i]);
    }
  }
  return o;
};

export default {
  debounce,
  throttle,
  cascade,
  typeOf,
  deepCopy,
};
