<template>
  <div class="con" :style="[itemStyle]" @click="handlerOption($event, data)">
    <div class="con-text">
      <i class="triangle" v-if="data.children"></i>
      <span class="con-icon">{{ data.name }}</span>
    </div>
  </div>
</template>

<script>
import { reactive, toRefs } from "vue";
import { useRouter } from "vue-router";
import { toggleClass } from "./class";

export default {
  name: "MenuItem",
  components: {},
  props: {
    // 内容
    data: {
      type: Object,
      default: () => ({}),
    },
    // 菜单样式
    itemStyle: {
      type: Object,
      default: () => ({}),
    },
  },
  setup() {
    const state = reactive({
      isExpand: "tree-expand",
    });

    const handlerToggle = (e) => {
      const curDom = e.currentTarget;
      const curIcon = curDom.childNodes[0].childNodes[0];
      const nextDom = curDom.parentNode.childNodes[2];
      toggleClass(curIcon, state.isExpand); // 三角图标 => 向下 或 向左
      toggleClass(nextDom, state.isExpand); // 子级菜单 => 显示 工 隐藏
      // console.log("cur dom", e.currentTarget);
    };

    const handlerOption = (e, data) => {
      data.children && data.children.length > 0
        ? handlerToggle(e)
        : handlerRouterLink(data);
    };

    const handlerRouterLink = (e) => {
      state.$router.push(e.path);
    };

    return {
      ...toRefs(state),
      handlerOption,
      handlerToggle,
      handlerRouterLink,
    };
  },
};
</script>

<style scoped>
.con:hover {
  background-color: orange;
  color: #fff;
}

.con > .con-text {
  position: relative;
}

.con > .con-text > span {
  margin-left: 14px;
}

.con > .con-text > .con-icon {
  display: inline-block;
  position: relative;
}

/* 三角形 */
.triangle {
  position: absolute;
  top: 50%;
  left: 4px;
  width: 0;
  height: 0;
  border-top: 5px solid transparent;
  border-right: 0;
  border-bottom: 6px solid transparent;
  border-left: 6px solid #aaa;
  display: inline-block;
  transform: translateY(-50%);
  transition: all ease-in-out 0.3s;
}

.triangle.tree-expand {
  top: calc(50% - 5px);
  transform: rotate(90deg);
  /* border-left-color: #fff; */
}
</style>
