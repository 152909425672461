<template>
  <div class="s-tree" :style="[menuStyle]">
    <Menu :router="router"></Menu>
  </div>
</template>

<script>
import Menu from "./Menu";
export default {
  name: "su-Menu",
  components: {
    Menu,
  },
  props: {
    // 路由数组菜单
    router: {
      type: Array,
      default: () => [],
    },
    // 每级菜单向左偏移*px
    offsetLeft: {
      type: Number,
      default: 15,
    },
    // 菜单宽度
    width: {
      type: Number,
      default: 300,
    },
    // 菜单样式
    menuStyle: {
      type: Object,
      default: (e) => ({
        width: `${e.width}px`,
      }),
    },
  },
  setup() {},
};
</script>

<style scoped>
.s-tree {
}
</style>
