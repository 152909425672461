// ****** global.js ******

/**
 * COPY => GTWISE
 * 全局变量
 * @author SLVD
 * @date 2020-10-05
 */
const $G = {
  ROWSPLIT: "|^", // 行分隔符
  UNITSPLIT: "$`", // 列分隔符
  ROWSPLITEX: "@^",
  UNITSPLITEX: "&`",
};

export default {
  $G,
};
