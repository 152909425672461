export const hasClass = function(obj, cls) {
  return obj.className.match(new RegExp("(\\s|^)" + cls + "(\\s|$)"));
};

export const addClass = function(obj, cls) {
  if (!hasClass(obj, cls)) obj.className += " " + cls;
};

export const removeClass = function(obj, cls) {
  if (hasClass(obj, cls)) {
    const reg = new RegExp("(\\s|^)" + cls + "(\\s|$)");
    obj.className = obj.className.replace(reg, "");
  }
};

export const toggleClass = function(obj, cls) {
  if (hasClass(obj, cls)) {
    removeClass(obj, cls);
  } else {
    addClass(obj, cls);
  }
};

export const getNextSibilingsNode = function(ele) {
  let e = ele.parentNode.childNodes;
  for (let i = 0, len = e.length; i < len; i++) {
    if (e[i].nodeType === 1 && e[i] === ele) {
      let j = i + 1; // 当前节点的下一个兄弟节点
      if (e[j] && e[j].nodeType === 1) {
        return e[j];
      }
    }
  }
};
