import basicLayout from "@/layouts/Basic";

export default [
  {
    path: "/sudidi",
    name: "sudidi",
    meta: {
      title: "sudidi",
    },
    component: basicLayout,
    redirect: '/sudidi/my',
    children: [
      {
        path: "my",
        name: "my",
        meta: {
          title: "my",
        },
        component: () => import("@/views/sudidi/my"),
      },
      {
        path: "svg",
        name: "svg",
        meta: {
          title: "svg",
        },
        component: () => import("@/views/sudidi/svg"),
      },
    ],
  },
  {
    path: "/aaaaa",
  },
];
