// ****** prototype.js ******

/**
 * COPY
 * 24小时制扩展 => var time2 = new Date().Format("yyyy-MM-dd HH:mm:ss");
 * @author SLVD
 * @date 2020-10-05
 */
Date.prototype.Format = function(fmt) {
  //author: meizz
  var o = {
    "M+": this.getMonth() + 1, //月份
    "d+": this.getDate(), //日
    "H+": this.getHours(), //小时
    "m+": this.getMinutes(), //分
    "s+": this.getSeconds(), //秒
    "q+": Math.floor((this.getMonth() + 3) / 3), //季度
    S: this.getMilliseconds(), //毫秒
  };
  if (/(y+)/.test(fmt))
    fmt = fmt.replace(
      RegExp.$1,
      (this.getFullYear() + "").substr(4 - RegExp.$1.length)
    );
  for (var k in o)
    if (new RegExp("(" + k + ")").test(fmt))
      fmt = fmt.replace(
        RegExp.$1,
        RegExp.$1.length == 1 ? o[k] : ("00" + o[k]).substr(("" + o[k]).length)
      );
  return fmt;
};

Array.prototype.$some = function(fun) {
  console.log("array.$some", this);
  let flag = false;
  for (let i = 0; i < this.length; i ++) {
    if (fun.call(undefined, this[i])) {
      flag = true
      return flag;
    }
  }
  return flag;
};


export default {
  // 修改原型链
};
