<template>
  <div :ref="refSplitter" :class="['s-splitter', { horizontal: horizontal }]">
    <div
      :ref="refSplitterBefore"
      :style="befofeSize"
      :class="[
        's-splitter-panel s-splitter-before',
        { auto: initSpace[0] === 'auto' },
      ]"
    >
      <slot name="before" />
    </div>
    <div
      :class="['s-splitter-separator', { horizontal: horizontal }]"
      v-touch="handleTouch"
    >
      <i :ref="refSplitterIcon" class="s-splitter-icon"></i>
    </div>
    <div
      :ref="refSplitterAfter"
      :style="afterSize"
      :class="[
        's-splitter-panel s-splitter-after',
        { auto: initSpace[1] === 'auto' },
      ]"
    >
      <p>{{ info }}</p>
      <slot name="after" />
    </div>
  </div>
</template>

<script>
import { reactive, toRefs, onMounted } from "vue";
// import { throttle } from "@/utils";

export default {
  name: "su-Splitter",
  components: {},
  props: {
    // 是否垂直显示
    horizontal: {
      type: Boolean,
      default: false,
      // default: true,
    },
    // 单位 默认百分比
    unit: {
      type: String,
      default: "%", // % 或 px
    },
    // 区域显示情况, [30], [30, 'auto'], ['', 30]
    limits: {
      type: Array,
      default: () => [20, "auto"],
      // default: () => ["auto", 20],
    },
    // 最小空间限制
    minSpace: {
      type: Number,
      default: 30, // 移动时的最小空间，单位px
    },
  },
  setup(props, context) {
    const state = reactive({
      info: "",
      domSplitter: "",
      domSplitterIcon: "",
      domSplitterBefore: "",
      domSplitterAfter: "",
      horizontal: props.horizontal,
      newLimits: props.limits, // 默认分割比例 [左, 右] | [上, 下]
      initSpace: [50, "auto"], // [初始宽度, 默认数值] => [30], [30, 'auto'], ['', 30]
      moveStyle: "w-resize",
      defaultStyle: "col-resize",
      canMove: false, // 可以移动
      curX: 0, // 点击分割条时，距离左侧的距离
      befofeSize: {}, // 内容所占空间(前)
      afterSize: {}, // 内容所占空间(后)
    });

    // ref
    const refSplitter = (el) => (state.domSplitter = el);
    const refSplitterIcon = (el) => (state.domSplitterIcon = el);
    const refSplitterBefore = (el) => (state.domSplitterBefore = el);
    const refSplitterAfter = (el) => (state.domSplitterAfter = el);

    const switchMove = () => {
      state.canMove = !state.canMove;
    };

    const parseF = (e, fix = 0) => {
      return parseFloat((parseFloat(e) || 0).toFixed(fix));
    };

    const setContentSize = () => {
      let typeSize = "";
      props.horizontal ? (typeSize = "height") : (typeSize = "width");
      state.befofeSize[typeSize] = state.initSpace[0] + props.unit;
      state.afterSize[typeSize] = state.initSpace[1] + props.unit;
    };

    // 初始化布局
    const initLayout = () => {
      let tmp = "auto";
      let tmpArr = [];
      let a = parseFloat(state.newLimits[0]); // 左 | 上
      let b = parseFloat(state.newLimits[1]); // 右 | 下

      // [30], [30, 'auto'], ['', 30]
      if (!isNaN(a) && typeof a === "number") {
        tmpArr = [parseF(a), tmp];
      } else if (!isNaN(b) && typeof b === "number") {
        tmpArr = [tmp, parseF(b)];
      } else {
        tmpArr = state.initSpace;
      }
      state.initSpace = [...tmpArr];
      state.newLimits = [...tmpArr];
      setContentSize();
    };

    // 获取拖动的宽度，转换成比例,
    const getMoveRatio = (e) => {
      let tmp = 0,
        ratio = 0,
        spaceSize = 0;
      // 垂直
      if (state.horizontal) {
        spaceSize = state.domSplitter.offsetHeight;
        tmp = e.offset.y / spaceSize;
      } else {
        spaceSize = state.domSplitter.offsetWidth;
        tmp = e.offset.x / spaceSize;
      }
      state.initSpace[0] >= 0 ? (ratio = tmp) : (ratio = -tmp);
      return {
        spaceSize,
        offset: parseFloat((ratio * 100).toFixed(4)),
      };
    };

    // 自定义指令
    const handleTouch = (e) => {
      state.info = JSON.stringify(e);
      let flag = 0;
      let offset = 0; // 偏移量
      let res = getMoveRatio(e);
      let spaceSize = res.spaceSize; // 总空间大小
      let minSpace = props.minSpace; // 最小空间
      let maxSpace = 0; // 最大空间

      if (props.unit === "%") {
        offset = res.offset;
        minSpace = (minSpace / spaceSize) * 100;
        maxSpace = 100 - minSpace;
      } else {
        state.horizontal ? (offset = e.offset.y) : (offset = e.offset.x);
        maxSpace = spaceSize - minSpace;
      }
      state.initSpace[0] >= 0 ? (flag = 0) : (flag = 1);
      let curSpace = state.newLimits[flag] + offset; // 当前的空间大小
      // 限制空间大小，超出后空间大小不变
      if (curSpace >= minSpace && curSpace <= maxSpace) {
        state.initSpace[flag] = curSpace;
        setContentSize();
      }

      if (e.isEnd) {
        state.newLimits[flag] = state.initSpace[flag];
      }
    };

    onMounted(() => {
      console.log("context", context);
      console.log("newLimits", state.newLimits);
      console.log("horizontalhorizontalhorizontal", props.horizontal);

      initLayout();
    });

    return {
      ...toRefs(state),
      refSplitter,
      refSplitterIcon,
      refSplitterBefore,
      refSplitterAfter,

      parseF,
      switchMove,
      initLayout,
      setContentSize,

      getMoveRatio,
      handleTouch,
    };
  },
};
</script>

<style lang="scss" scoped>
@import "./index.scss";
</style>