import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import util from "./utils";

import "./config/permission";

import "@/assets/common.css";
import "@/assets/layout.css";

// https://www.cnblogs.com/yf-html/p/12753540.html

// 设置全局
const globalObj = {
  $U: util,
};

const app = createApp(App);
Object.assign(app.config.globalProperties, globalObj);

app.directive("touch", {
  beforeMount(el, binding) {
    // let tmpArr = ["client", "screen", "offset", "page", "layer", "movement"];
    let info = {
      isEnd: false,
      start: {}, // 开始位置
      end: {}, // 结束位置
      offset: {}, // 位移位置
      position: {}, // 相对于当前浏览器窗口位置
      direction: [], // 移动的方向 ['x轴'， 'y轴'] Up Down Left Fight
    };
    let useAttr = ["client"];
    console.log("v-touch", el, binding.value, util);

    let elm = document.body;

    const setPosition = (arr, evt) => {
      let e = {};
      for (let o of arr) {
        if (!e[o]) e[o] = {};
        e[o]["x"] = evt[`${o}X`];
        e[o]["y"] = evt[`${o}Y`];
      }
      return e;
    };

    const handleMousemove = util.throttle(function(e) {
      let tmp = setPosition(useAttr, e)[useAttr[0]];
      info.position = setPosition(useAttr, e)[useAttr[0]];
      info.offset = {
        x: tmp.x - info.start.x,
        y: tmp.y - info.start.y,
      };
      info.offset.x > 0 ? (info.direction[0] = "R") : (info.direction[0] = "L");
      info.offset.y > 0 ? (info.direction[1] = "D") : (info.direction[1] = "U");
      binding.value.call("", info);
    }, 30);

    // 按下
    const handleMousedown = (e) => {
      info.isEnd = false;
      console.log("aaaaa", e.screenX);
      util.addClass(el, "s-move");
      console.log("XXX", util);
      info.start = setPosition(useAttr, e)[useAttr[0]];
      elm.addEventListener("mouseup", handleMouseup);
      elm.addEventListener("mousemove", handleMousemove);
    };

    // 松开
    const handleMouseup = (e) => {
      console.log("sssssss");
      util.removeClass(el, "s-move");
      info.isEnd = true;
      info.end = info.position = setPosition(useAttr, e)[useAttr[0]];
      binding.value.call("", info);
      elm.removeEventListener("mouseup", handleMouseup);
      elm.removeEventListener("mousemove", handleMousemove);
    };

    el.addEventListener("mousedown", handleMousedown);
  },
  mounted() {},
  beforeUpdate() {},
  updated() {},
  beforeUnmount() {}, // new
  unmounted() {},
});

app
  .use(store)
  .use(router)
  .mount("#s-app");

// router.isReady().then(() => app.mount("#app"));
