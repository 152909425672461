<template>
  <div class="s-basic fixed fit">
    <Splitter>
      <template v-slot:before>
        <div class="">
          <Menu :router="menuRouter"></Menu>
        </div>
      </template>
      <template v-slot:after>
        <div class="s-pa-sm h100">
          <router-view />
        </div>
      </template>
    </Splitter>
  </div>
</template>

<script>
import Menu from "@c/Menu";
import Splitter from "@c/Splitter";
import { constantRoutes, asyncRoutes } from "@/router";
import { reactive, toRefs, inject, onMounted } from "vue";

export default {
  name: "layoutIndex",
  components: {
    Menu,
    Splitter,
  },
  setup() {
    const $U = inject("$U");
    const state = reactive({
      menuRouter: asyncRoutes,
      drawLeft: 300, // 左侧抽屉宽度
    });

    onMounted(() => {
      console.log("layout $U", $U.$G, constantRoutes);
    });

    return {
      ...toRefs(state),
    };
  },
};
</script>

<style lang="scss" scoped>
@import "./index.scss";
</style>
