const moduleFiles = require.context("@/utils/utilsModules", false, /\.js$/);

/**
 * tips: 每个路由文件下的函数名称不要重复，否则会覆盖
 * @author SLVD
 * @date 2020-10-14
 */
const modules = moduleFiles.keys().reduce((modules, modulePath) => {
  const value = moduleFiles(modulePath);
  Object.assign(modules, value.default);
  return modules;
}, {});
console.log("utils => all modules: ", modules);

export default {
  ...modules,
};

export const debounce = modules.debounce;
export const throttle = modules.throttle;
