<template>
  <div class="tree-item" v-for="(it, key) in router" :key="key">
    <MenuItem v-if="!it.children" :data="it" :itemStyle="itemStyle"></MenuItem>
    <template v-else>
      <MenuItem :data="it" :itemStyle="itemStyle"></MenuItem>
      <div class="group">
        <Menu
          :router="it.children"
          :num="num + 1"
          :offset-left="offsetLeft"
        ></Menu>
      </div>
    </template>
  </div>
</template>

<script>
import MenuItem from "./MenuItem";
import { reactive, toRefs } from "vue";
export default {
  name: "Menu",
  components: {
    MenuItem,
  },
  props: {
    // 第*次循环
    num: {
      type: Number,
      default: 0,
    },
    // 每级菜单向左偏移*px
    offsetLeft: {
      type: Number,
      default: 15,
    },
    // 路由数组菜单
    router: {
      type: Array,
      default: () => [],
    },
    // 菜单样式
    itemStyle: {
      type: Object,
      default: (e) => ({
        "padding-left": `${e.num * e.offsetLeft}px`,
      }),
    },
    // 菜单hover状态
    itemHoverStyle: {
      type: Object,
      default: () => ({
        "--background-color-hover": "#F00",
        color: "#0cc",
      }),
    },
  },
  setup() {
    const state = reactive({
      toggle: false,
    });

    return {
      ...toRefs(state),
    };
  },
};
</script>

<style scoped>
.tree-item {
  cursor: pointer;
  text-align: left;
}

.group {
  display: none;
}
.group.tree-expand {
  display: block;
}
</style>
