import router, { asyncRoutes, constantRoutes } from "@/router";

console.log("cur router", router);

// router.beforeEach(async (to, from, next) => {
//   console.log("to", to);
//   console.log("from", from);
//   console.log("routeRecords", router.getRoutes());
//   //   console.log("asynRoutes", asyncRoutes);
//   console.log("constantRoutes", constantRoutes);
//   //   router.addRoute(to);
//   // router.addRoute(asyncRoutes);
//   // router.addRoutes(asyncRoutes)

//   router.addRoute({
//     path: to.path,
//     name: to.name,
//     component: import("@/views/sudidi/tmp.sudidi.com/index.vue"),
//   });
// //   next();
//   // debugger;
// });

// import { START_LOCATION } from "vue-router";
// router.beforeEach((to, from) => {
//   console.log("START_LOCATION", START_LOCATION);
//   console.log("to to", to);
//   //   debugger;
//   if (from === START_LOCATION) {
//     console.log("START", from);
//   }

//   router.addRoute(asyncRoutes[0]);
//   //   if (to.meta.requiresAuth && !isAuthenticated) return false;
// });
