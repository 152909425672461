<template>
  <router-view />
</template>

<script>
import { reactive, toRefs, provide, readonly, onMounted } from "vue";
import util from "./utils";

export default {
  name: "App",
  components: {
    // HelloWorld,
  },
  setup() {
    const state = reactive({
      util,
    });

    provide("$U", readonly(util));

  
    onMounted(() => {
      // (原型)js中所有的函数都有一个prototype属性，其所有的属性和方法，
      //  都会被构造函数的实例继承 都能被构造函数的实例对象共享访问
      // ============&&&&&&
      // prototype 就是调用构造函数所创建的那个实例对象的原型(proto)
      // ============&&&&&&
      // (构造器)js中constroctor存在于每一个函数的prototype属性中，指向函数本身
      // ============&&&&&&
      // (原型链)js中对象都有一个内置属性，即_proto_(隐式原型链属性),
      // 一般情况下它指向创建它的构造函数的prototype属性。另外函数比较特殊，它也有此属性


    });

    return {
      ...toRefs(state),
    };
  },
};
</script>

<style>

</style>
