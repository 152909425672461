<template>
  <div class="s-main fixed fit">
    <router-view />
  </div>
</template>

<script>

export default {
  name: "mainIndex",
  components: {
  },
  setup() {
  },
};
</script>

<style lang="scss" scoped>
@import "./index.scss";

</style>
