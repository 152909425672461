
import basicLayout from "@/layouts/Basic";

export default [
  {
    path: "/temp",
    name: "temp",
    meta: {
      title: "temp",
    },
    component: basicLayout,
    children: [
      {
        path: "",
        name: "hello",
        meta: {
          title: "hello",
        },
        component: () => import("@/views/temp/hello"),
      },
      {
        path: "table",
        name: "table",
        meta: {
          title: "table",
        },
        component: () => import("@/views/temp/table"),
      },
      {
        path: "printjs",
        name: "printjs",
        meta: {
          title: "printjs",
        },
        component: () => import("@/views/temp/printjs"),
      },
      {
        path: "xspreadjs",
        name: "xspreadjs",
        meta: {
          title: "xspreadjs",
        },
        component: () => import("@/views/temp/xspreadjs"),
      },
    ],
  },
];
