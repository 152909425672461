import { createStore } from 'vuex'

export default createStore({
  state: {
  },
  mutations: {
  },
  actions: {
  },
  modules: {
  }
})

// http://www.qiutianaimeili.com/html/page/2019/04/ec8kbyvrsz4.html