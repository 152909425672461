import mainLayout from "@/layouts/Main";

export default [
  {
    path: "/",
    component: mainLayout,
    children: [
      {
        path: "",
        name: "Hello",
        component: () => import("@/views/template"),
      },
      {
        path: "404",
        name: "404",
        component: () => import("@/views/error/404"),
      },
      {
        path: "500",
        name: "500",
        component: () => import("@/views/error/500"),
      },
    ],
  },

  // {
  //   path: "/xspreadsheet",
  //   component: () => import("@/views/sudidi/tmp."),
  // },

  {
    path: "/:catchAll(.*)",
    redirect: "/404"
  }
];
